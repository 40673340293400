import { faBath } from '@fortawesome/pro-light-svg-icons/faBath';
import { faBedAlt } from '@fortawesome/pro-light-svg-icons/faBedAlt';
import { faCouch } from '@fortawesome/pro-light-svg-icons/faCouch';
import noPhotoSmall from '@propertypal/shared/src/resources/fallbacks/no-photo-icon-small.png';
import noPhoto from '@propertypal/shared/src/resources/fallbacks/no-photo-icon.png';
import { Property } from '@propertypal/shared/src/types/property';
import { PropertySearchResult } from '@propertypal/shared/src/types/propertySearch';
import { formatNumber } from '@propertypal/shared/src/utils/floatToCurrency';
import { getMainImage, getThumbImages, getAgentLogos } from '@propertypal/shared/src/utils/property';
import { SubHeading, Text } from '@propertypal/web-ui/src/typography';
import React, { FunctionComponent, useRef } from 'react';
import { useTheme } from 'styled-components';
import FontAwesomeIcon from '../icons/FontAwesomeIcon';
import LazyImage from '../image/LazyImage';
import StartEndTime from '../time/StartEndTime';
import EnergyRatingIcon from './EnergyRatingIcon';
import OpenViewingOverlay from './OpenViewingOverlay';
import PropertyBadge from './PropertyBadge';
import PropertyBanner from './PropertyBanner';
import {
  Container,
  Content,
  DescriptionBox,
  ExtraDescription,
  FavouriteBox,
  HeroImageBox,
  HeroNoImage,
  ImageContent,
  LinkContainer,
  SummaryRow,
  ThumbImage,
  ThumbImageContainer,
  ViewsPill,
} from './PropertyBox.style';
import PropertyBoxFooter from './PropertyBoxFooter';
import PropertyDistance from './PropertyDistance';
import PropertyFavourite from './PropertyFavourite';
import PropertyPrice from './PropertyPrice';
import ShowHomesBox from './ShowHomesBox';

interface Props {
  property: Property;
  disablePremiumProperties?: boolean;
  userSection?: boolean;
  onFavourite?: () => (favourited: boolean, propertyId: number) => Promise<boolean>;
  overlay?: React.ReactElement;
  hideFooter?: boolean;
  locationDetail?: PropertySearchResult['locationDetail'];
  imageLocator?: string;
  thumbImageLocator?: string;
  showAuctionsLabel?: boolean;
  showOpenViewingLabel?: boolean;
  useWideDisplay?: boolean;
  loading?: 'lazy' | 'eager';
}

const PropertyBox: FunctionComponent<Props> = (props) => {
  const { property, overlay, hideFooter, useWideDisplay } = props;
  const isPremium =
    !props.disablePremiumProperties &&
    props.property.premiumListing &&
    !props.userSection &&
    !props.showOpenViewingLabel;
  const enhancedBranding = props.property.enhancedBranding && !props.disablePremiumProperties && !props.userSection;
  const { srpBrandingBgColour } = props.property.enhancedBrandingColours || {};
  const theme = useTheme();
  const [heroImage, heroHash] = getMainImage(property, props.imageLocator);
  const thumbImages = getThumbImages(property, props.thumbImageLocator);
  const accountLogos = getAgentLogos(property, 'STANDARD');
  const showAuctionLabel = !props.userSection && props.showAuctionsLabel && property.auctionTime;
  const showOpenViewingLabel = !props.userSection && !isPremium && props.showOpenViewingLabel && property.openViewing;
  const showOpenViewingOverlay = !showOpenViewingLabel && property.openViewing && !property.openViewing.past;
  const descriptionBox = useRef<HTMLDivElement>(null);
  const canFavourite = props.onFavourite && property.type === 'property';

  return (
    <Container className="pp-property-box" $useWideDisplay={useWideDisplay} $userSection={!!props?.userSection}>
      {showAuctionLabel && property.listingTime?.type === 'auction' && property.listingTime.timeText && (
        <StartEndTime text={property.listingTime.timeText} />
      )}

      {showOpenViewingLabel && property.openViewing?.text && <StartEndTime text={property.openViewing.text} />}

      {!!props.property.distance && (
        <PropertyDistance distance={props.property.distance} locationDetail={props.locationDetail} />
      )}

      {overlay}

      <LinkContainer
        href={property.path}
        prefetch={false}
        $userSection={!!props?.userSection}
        $isPremium={isPremium}
        $premierColor={srpBrandingBgColour}
      >
        <Content $useWideDisplay={useWideDisplay} $userSection={props?.userSection}>
          <ImageContent $useWideDisplay={useWideDisplay} $userSection={props?.userSection}>
            <HeroImageBox>
              {!heroImage && (
                <HeroNoImage src={props.userSection ? noPhotoSmall.src : noPhoto.src} alt="No photo available" />
              )}

              {!!heroImage && (
                <LazyImage
                  src={heroImage}
                  blurHash={heroHash}
                  width={880}
                  height={645}
                  alt={`${property.displayAddress} photo`}
                  loading={props.loading}
                />
              )}

              {!props.disablePremiumProperties && <PropertyBanner property={property} />}

              {!showOpenViewingOverlay && !!property.viewsInLast7Days && (
                <ViewsPill>
                  <b>{formatNumber(property.viewsInLast7Days)}</b> views this week
                </ViewsPill>
              )}

              {/* Prevent auction badge from rendering when there is also an open viewing on property */}
              {/* Auction time is also disabled if the auction label is displayed at the top of the box instead */}
              {!showOpenViewingOverlay && <PropertyBadge property={property} showAuctionTime={!showAuctionLabel} />}

              {showOpenViewingOverlay && property.openViewing && (
                <OpenViewingOverlay
                  openViewing={property.openViewing}
                  themeName="dark"
                  containerStyle={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    bottom: 0,
                  }}
                />
              )}
            </HeroImageBox>

            {!props.disablePremiumProperties && isPremium && (
              <ThumbImageContainer>
                {thumbImages &&
                  thumbImages.map((thumbImage, index) => (
                    <ThumbImage key={thumbImage.url}>
                      <LazyImage
                        src={thumbImage.url}
                        blurHash={thumbImage.blurHash}
                        alt={`${property.displayAddress} photo ${index + 2}`}
                        aria-hidden="true"
                        width={340}
                        height={255}
                        loading={props.loading}
                      />
                    </ThumbImage>
                  ))}
              </ThumbImageContainer>
            )}
          </ImageContent>

          <DescriptionBox ref={descriptionBox}>
            {canFavourite && (
              <FavouriteBox>
                <PropertyFavourite
                  favourited={property.shortlisted}
                  // @ts-ignore
                  onFavourite={(favourited) => props.onFavourite()(favourited, property.id)}
                  // unique key needed in infinite grid, once item above is removed from list
                  // it would inherit its favourited state value in PropertyFavourite
                  key={property.id}
                />
              </FavouriteBox>
            )}

            <div>
              <PropertyPrice
                containerStyle={{ marginBottom: 12, fontWeight: 600, paddingRight: canFavourite ? 60 : 0 }}
                price={property.price}
                alternativeCurrencyPrice={property.alternativeCurrencyPrice}
                fontSize={34}
                status={property.status}
                comingSoonText={property.comingSoonText}
                prefixBreak
              />

              <SubHeading
                color={theme.textMid}
                fontSize={16}
                mb={12}
                fontWeight="600"
                data-testid="property-box-address"
                style={{ maxWidth: 400 }}
              >
                {property.displayAddress.replace(/\s+/g, ' ')}
              </SubHeading>

              <Text fontSize={14} color={theme.textLight} mb={10}>
                {props.property.briefText}
              </Text>

              <SummaryRow>
                {!!property.numBedrooms && (
                  <>
                    <FontAwesomeIcon icon={faBedAlt} color={theme.textDark} size={22} />
                    <Text color={theme.textLight}>{property.numBedrooms}</Text>
                  </>
                )}

                {!!property.numBathrooms && (
                  <>
                    <FontAwesomeIcon icon={faBath} color={theme.textDark} size={22} />
                    <Text color={theme.textLight}>{property.numBathrooms}</Text>
                  </>
                )}

                {!!property.numReceptionRooms && (
                  <>
                    <FontAwesomeIcon icon={faCouch} color={theme.textDark} size={22} />
                    <Text color={theme.textLight}>{property.numReceptionRooms}</Text>
                  </>
                )}

                <EnergyRatingIcon containerStyle={{ marginBottom: 15 }} property={property} hideEpcText />
              </SummaryRow>

              {isPremium && (
                <ExtraDescription $useWideDisplay={useWideDisplay}>
                  <SubHeading mt={30} fontSize={16}>
                    DESCRIPTION
                  </SubHeading>

                  <Text data-testid="propertyDescription" fontSize={14}>
                    {property.description}
                  </Text>
                </ExtraDescription>
              )}
            </div>

            <div>
              <ShowHomesBox property={property} fontSize={14} />

              {!isPremium && !enhancedBranding && !hideFooter && (
                <PropertyBoxFooter key="1" property={property} logos={accountLogos} />
              )}
            </div>
          </DescriptionBox>
        </Content>

        {(isPremium || enhancedBranding) && !hideFooter && (
          <PropertyBoxFooter key="2" property={property} premium logos={accountLogos} />
        )}
      </LinkContainer>
    </Container>
  );
};

export default React.memo(PropertyBox);
