import styled from 'styled-components';

export const Container = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
`;

export const BlurredImage = styled.div<{ blurCss?: string }>`
  transform: scale(1.2);
  filter: blur(24px);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;

  ${({ blurCss }) => blurCss}
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  display: block;
  position: relative;
  z-index: 2;
`;
