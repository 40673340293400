import { Property } from '@propertypal/shared/src/types/property';
import camelCase from 'lodash/camelCase';
import React, { FunctionComponent } from 'react';
import { BannerText, Container } from './PropertyBanner.style';

interface Props {
  property: Property;
  disableFeatured?: boolean;
  disablePremium?: boolean;
  hidePremierListing?: boolean;
}

const BANNER_TYPES = ['saleAgreed', 'letAgreed', 'sold', 'let', 'underOffer'];

const PropertyBanner: FunctionComponent<Props> = (props) => {
  const developmentStatus = props.property.developmentStatus || props.property.development?.developmentStatus;

  if (props.property.comingSoonText) {
    const fontSize = props.property.comingSoonText.length > 20 ? 12 : 14;

    return (
      <Container type={camelCase(props.property.comingSoonText)} className="pp-property-box-banner">
        <BannerText data-testid="developmentText" style={{ fontSize }}>
          {props.property.comingSoonText.toUpperCase()}
        </BannerText>
      </Container>
    );
  }

  if (props.property.status && BANNER_TYPES.includes(props.property.status.key)) {
    return (
      <Container type="featured" className="pp-property-box-banner">
        <BannerText data-testid="bannerText">{props.property.status.text.toUpperCase()}</BannerText>
      </Container>
    );
  }

  if (developmentStatus && developmentStatus.key !== 'available') {
    const fontSize = developmentStatus.text.length > 20 ? 12 : 14;

    return (
      <Container type={developmentStatus.key} className="pp-property-box-banner">
        <BannerText data-testid="developmentText" style={{ fontSize }}>
          {developmentStatus.text.toUpperCase()}
        </BannerText>
      </Container>
    );
  }

  if (props.property.premiumListing && !props.disablePremium && !props.hidePremierListing) {
    return (
      <Container type="premier" className="pp-property-box-banner">
        <BannerText data-testid="premierText">PREMIER</BannerText>
      </Container>
    );
  }

  if (props.property.featuredListing && !props.disableFeatured) {
    return (
      <Container type="featured" className="pp-property-box-banner">
        <BannerText data-testid="featuredText">FEATURED {developmentStatus && 'DEVELOPMENT'}</BannerText>
      </Container>
    );
  }

  return null;
};

export default PropertyBanner;
