import {
  laptopMaxXL,
  mobileXXLargeMax,
  largeTabletMax,
  laptopLargeMax,
} from '@propertypal/shared/src/constants/mediaQueries';
import Link from 'next/link';
import styled from 'styled-components';

const userSectionMax = 'max-width: 1024px';
const userSectionMin = 'min-width: 650px';

const userSectionQuery = `@media screen and (${userSectionMax}) and (${userSectionMin})`;

interface UseWideDisplay {
  $useWideDisplay?: boolean;
}

interface UserSection {
  $userSection?: boolean;
}

interface Loading {
  $isLoading?: boolean;
}

export const Container = styled.li<UseWideDisplay & UserSection & Loading>`
  position: relative;
  display: ${({ $userSection }) => ($userSection ? 'inline-' : '')}flex;
  flex-direction: column;
  ${({ $userSection }) =>
    $userSection
      ? `
        width: 100%;

        @media screen and (min-width: 1025px) {
          max-width: 340px;
        }
        
        ${userSectionQuery} {
          display: flex;
          flex: 1;
          flex-basis: 100%;
        }
      `
      : ``}

  ${(props) =>
    props.$useWideDisplay &&
    `
    grid-column: 1 / span 4;
    
    @media (${laptopLargeMax}) {
      grid-column: 1 / span 3;
    }
    
    @media (${largeTabletMax}) {
      grid-column: 1 / span 2;
    }

    @media (${mobileXXLargeMax}) {
      grid-column: 1 / span 1;
    }
  `}
`;

export const Wrapper = styled.div<UserSection & Loading & { $premierColor?: string; $isPremium: boolean }>``;

export const LinkContainer = styled(Link)<UserSection & Loading & { $premierColor?: string; $isPremium?: boolean }>`
  display: flex;
  height: 100%;
  flex-direction: column;
  background-color: ${({ theme, $isLoading }) => ($isLoading ? 'transparent' : theme.background)};
  flex: 1;

  &:visited h2 {
    color: ${({ theme }) => theme.backgroundMid};
  }

  @media (${mobileXXLargeMax}) {
    // Fixes box height on iOS 10
    height: auto;
  }

  ${({ $userSection, theme, $isLoading }) =>
    !$userSection
      ? `
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      border-radius: 10px;

      &:hover {
        background-color: ${$isLoading ? 'transparent' : theme.backgroundLighter};
      }
      `
      : ``}

  ${(props) =>
    props.$isPremium &&
    props.$premierColor &&
    `
    border: 2px solid ${props.$premierColor};
    border-radius: 6px;
    overflow: hidden;
  `}
`;

export const Content = styled.div<UseWideDisplay & UserSection>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;

  ${({ $userSection }) =>
    $userSection &&
    `      
      ${userSectionQuery} {
        flex-direction: row;
        flex-grow: 1;
        width: 100%;
      }
    `}

  ${(props) =>
    props.$useWideDisplay &&
    `
    flex-direction: row;
    
    @media (${mobileXXLargeMax}) {
      flex-direction: column;
    }
  `}
`;

export const ImageContent = styled.div<UseWideDisplay & UserSection>`
  align-self: flex-start;
  width: 100%;

  ${({ $userSection }) =>
    $userSection &&
    `      
      ${userSectionQuery} {
        max-width: 380px;
      }
    `}

  ${(props) =>
    props.$useWideDisplay &&
    `
    flex-basis: 394px;
    
    @media (${laptopMaxXL}) {
      flex-basis: auto;
      width: 50%;
      flex-direction: column;
      max-width: 460px;
    }
    
    @media (${mobileXXLargeMax}) {
      width: 100%;
      max-width: none;
    }
  `}
`;

export const FavouriteBox = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeroImageBox = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;
  flex: 1;
`;

export const ViewsPill = styled.p`
  padding: 4px 10px;
  background-color: ${({ theme }) => theme.white};
  border-radius: 20px;
  color: ${({ theme }) => theme.textDark};
  position: absolute;
  bottom: 8px;
  left: 8px;
  z-index: 2;
  font-size: 14px;
`;

export const ThumbImageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
`;

export const ThumbImage = styled.div`
  width: 32%;
`;

export const DescriptionBox = styled.div`
  position: relative;
  padding: 16px 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

export const ExtraDescription = styled.div<UseWideDisplay>`
  max-width: 600px;
  display: ${({ $useWideDisplay }) => ($useWideDisplay ? 'block' : 'none')};

  @media (${mobileXXLargeMax}) {
    display: block;
  }
`;

export const SummaryRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 15px 0 0;

  & > svg {
    color: ${({ theme }) => theme.textLight};
    margin: 0 10px 15px 0;
  }

  & > p {
    margin: 0 20px 15px 0;
  }
`;

export const HeroNoImage = styled.img`
  width: 100%;
  background-color: ${(props) => props.theme.backgroundLighter};
`;
